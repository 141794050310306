import * as React from 'react';
import Layout from '../../components/layout';
import Article from '../../components/article';
import { Seo } from '../../components/seo';

const VSCodeLinters = ({ location }) => {
  return (
    <Layout pageTitle="Visual Studio Code Linters Starter Kit for Web Developers">
      <Seo index={false} follow={false} pathname={location.pathname} />
      <Article>
        <h1 className="fancy">
          Visual Studio Code Linters Starter Kit for Web Dev&shy;elop&shy;ers
        </h1>
        <p>
          Here are the bets linters for new web web developers for the{' '}
          <a href="/articles/best-ide-beginning-web-developer/">
            Visual Studio Code editor
          </a>
          :
        </p>
        <ul>
          <li>
            CSS:{' '}
            <a href="https://marketplace.visualstudio.com/items?itemName=shinnn.stylelint">
              Stylint
            </a>
          </li>
          <li>
            HTML:{' '}
            <a href="https://marketplace.visualstudio.com/items?itemName=mkaufman.HTMLHint">
              HTMLHint
            </a>
          </li>
          <li>
            Javascript:{' '}
            <a href="https://marketplace.visualstudio.com/items?itemName=dbaeumer.vscode-eslint">
              ESLint
            </a>
          </li>
        </ul>
        <p>
          Install these to improve the quality of your code and reduce
          headaches! (Beats the heck out of Tylenol. 😉)
        </p>
      </Article>
    </Layout>
  );
};
export default VSCodeLinters;
